<template>
<div>
    <page-header :title="`Thiết lập phân loại khảo sát`" :items="breadcrumbs" />
    <div class="card">
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-sm-10"></div>
                <div class="col-sm-2">
                    <div class="text-sm-right">
                        <router-link :to="{name: 'admin.config.plks.create'}">
                            <b-button variant="success">
                                <i class="bx bx-plus"></i> Thêm mới
                            </b-button>
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- Table data -->

            <div class="table-responsive">
                <table class="table table-hover table-centered table-nowrap">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col" style="width: 40rem">Loại khảo sát</th>
                            <th scope="col" class="text-center">Thứ tự</th>
                            <th scope="col" class="text-center">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="entry in entries" :key="entry.id">
                            <td>
                                {{ entry.name }}
                            </td>
                            <td class="text-center">
                                {{ entry.order }}
                            </td>
                            <td style="font-size: 16px" class="text-center">
                                <router-link v-b-tooltip.hover :title="`Sửa`" class="text-primary" :to="{name: 'admin.config.plks.edit', params: {id:entry.id , form: entry}}">
                                    <i class="bx bx-edit"></i></router-link>
                                <a href="javascript: void(0);" v-b-tooltip.hover :title="`Xoá`" class="text-danger" @click.prevent="deleteEntry(entry)"> <i class="bx bx-trash"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import Swal from 'sweetalert2';
import {
    httpClient
} from "../../../_utils/httpClient";

export default {
    name: 'list',
    components: {
        PageHeader,
    },
    data: () => ({
        breadcrumbs: [{
            text: 'Trang chủ',
            to: '/admin/home',
        }, {
            text: 'Cấu hình',
            to: '/admin/config',
            active: true,
        }, ],
        entries: [],
    }),
    methods: {
        async getData() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'phanloaiquyetdinh'
                }
            })
            if (response.status === 200 && response.data.code === 0) {
                this.entries = response.data.data
            }
        },
        async deleteEntry(entry) {
            await Swal.queue([{
                title: 'Xoá thiết lập: ' + entry.name,
                confirmButtonText: 'xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`config/delete`, {
                        id: entry.id
                    })
                    console.log('response', response)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Xoá thành công',
                        });
                        await this.getData();
                    } else {
                        Swal.insertQueueStep({
                            title: 'Xoá không thành công',
                        });
                    }
                    return true;
                },
            }, ]);

        }
    },
    created() {
        this.getData();
    },
};
</script>

<style scoped>

</style>
